body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'NunitoSans';
  font-weight: 1000;
  src: url('./NunitoSans/NunitoSans-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'NunitoSansBold';
  font-weight: 1000;
  src: url('./NunitoSans/NunitoSans-Bold.ttf') format('truetype');
}

/* Create a custom scrollbar */
::-webkit-scrollbar {
  width: 12px; /* Set the width to your desired size */
}

/* Style the track */
::-webkit-scrollbar-track {
  background: #dddddd; /* Add a background color */
}

/* Style the thumb */
::-webkit-scrollbar-thumb {
  background-color: black; /* Add a background color */
  border-radius: 6px; /* Round the corners */
  border: 3px solid #dddddd; /* Add a border */
}

.border {
  position: absolute;
  z-index: 1;
  background-color: rgba(0,0,0,0);
  /* needs to be fixed */
  height: calc(362.5vh - 30px);
  width: calc(100vw - 42px);
  top: 15px;
  left: 15px;
  bottom: 15px;
  right: 27px;
}

@media screen and (max-width: 1000px) {
  .border {
    width: calc(100vw - 30px);
    right: 15px;
  }
}

@media screen and (max-height: 400px) {
  .border {
    width: calc(100vw - 30px);
    right: 15px;
  }

  .lightDarkButton {
    padding-bottom: 0 !important;
  }
}
