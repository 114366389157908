div {
  font-family: "NunitoSans";
}

@media screen and (max-width: 768px) {
 .name{
  font-size: 42px !important;
 }

 .downArrow{
  opacity: 0;
 }

 .experience {
  padding: 25px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  transform: none !important;
  left: 0 !important;
 }

 .projects {
  padding: 25px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  transform: none !important;
  left: 0 !important;
 }

 .contact {
  padding: 25px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  transform: none !important;
  left: 0 !important;
 }
}

@media screen and (max-height: 415px) {
  .name{
   font-size: 42px !important;
  }
 
  .downArrow{
   opacity: 0;
  }
 
  .experience {
    opacity: 0;
  }
 
  .projects {
    opacity: 0;
  }
 
  .contact {
    opacity: 0;
  }

  .blurb {
    opacity: 0;
  }
 }

.name {
  top: 0;
  height: 8%;
  font-size: 64px;
  margin-bottom: 0;
  margin-top: 20px;
}

.job {
  top: 9%;
  font-size: 32px;
}

/* Light mode styles */
.light-mode {
  background-color: #ffffff;
  color: #000000;
}

/* Dark mode styles */
.dark-mode {
  background-color: #121212;
  color: #ffffff;
}

.downArrow{
	position: absolute;
	bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
}
.bounce {
	-moz-animation: bounce 3s infinite;
	-webkit-animation: bounce 3s infinite;
	animation: bounce 3s infinite;
}
@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

.background {
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100%;
  max-width: 100%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.title {
  position: absolute;
  left: 0;
  margin: 20px;
  font-family: NunitoSans;
  z-index: 3;
}

.gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 500px;
  z-index: 0;
}

.blurb {
  position: absolute;
  bottom: 0;
  right: 10px;
  width: 180px;
  text-align: left;
  margin: 20px;
  font-family: NunitoSansBold;
  font-size: 17.5px;
  word-wrap: break-word;
  z-index: 3;
}

.scroll {
  width: 100%;
  text-align: center;
}

.experience {
  bottom: 150px;
  position: absolute;
  z-index: 3;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}

.projects {
  bottom: 110px;
  position: absolute;
  z-index: 3;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}

.contact {
  bottom: 70px;
  position: absolute;
  z-index: 3;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}

.border1 {
  position: absolute;
  background-color: rgba(0,0,0,0);
  z-index: 1;
  /* needs to be fixed */
  /* height: calc(362.5vh - 30px); */
  width: calc(100vw - 42px);
  top: 15px;
  left: 15px;
  bottom: 0px;
  right: 27px;
  border-bottom: none !important;
}

@media screen and (max-width: 1000px) {
  .border1 {
    width: calc(100vw - 30px);
    right: 15px;
  }
}

@media screen and (max-height: 400px) {
  .border1 {
    width: calc(100vw - 30px);
    right: 15px;
  }
}
