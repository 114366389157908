@media screen and (max-width: 850px) {
    .card5, .card6, .card7, .card8 , .card9, .card10, .card11, .card12, .card13, .card14, .card15, .card16, .card17{
      margin: 0 auto !important;
    }
}

.pageThree {
  display: flex;
  align-items: flex-start;
  /* height: 100vh; */
  width: 100%;
  max-width: 100%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  flex-direction: column;
  gap: 20px;
}

/* @media screen and (max-width: 364px) {
  .card5{
      width: 240px !important;
      min-height: 241px !important;
  }

  .card6{
      width: 240px !important;
      min-height: 322px !important;
  }

  .card7{
      width: 240px !important;
      min-height: 260px !important;
  }

  .card8{
      width: 240px !important;
      min-height: 240px !important;
  }

  .card9{
    width: 240px !important;
    min-height: 240px !important;
  }

  .card10{
    width: 240px !important;
    min-height: 240px !important;
  }

  .card11{
    width: 240px !important;
    min-height: 240px !important;
  }

  .card12{
    width: 240px !important;
    min-height: 240px !important;
  }

  .card13{
    width: 240px !important;
    min-height: 240px !important;
  }

  .card14{
    width: 240px !important;
    min-height: 240px !important;
  }

  .card15{
    width: 240px !important;
    min-height: 240px !important;
  }

  .card16{
    width: 240px !important;
    min-height: 240px !important;
  }

  .card17{
    width: 240px !important;
    min-height: 240px !important;
  }
} */

.border3 {
    position: static;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0);
    width: calc(100vw - 42px);
    margin-left: 15px;
    bottom: 15px !important;
    right: 27px;
    /* height: 1000px; */
    top: 100%;
    border-top: none !important;
    border-bottom: none !important;
  }
  
  @media screen and (max-width: 1000px) {
    .border3 {
      width: calc(100vw - 30px);
      right: 15px;
    }
  }
  
  /* @media screen and (max-height: 400px) {
    .border3 {
      width: calc(100vw - 30px);
      right: 15px;
    }
  } */